import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

function EditQuestions() {
	const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate();

	const [question_data, setQuestion_data] = useState({
		questionText: "",
		readwriteAns: "",
		kineticAns: "",
		audioAns: "",
		visualAns: ""
	})

	function handleChange(evt) {
		const value = evt.target.value;
		setQuestion_data({
			...question_data,
			[evt.target.name]: value
		});
	}

	function submitQuestion() {
		const fieldsAreEmpty = Object.values(question_data).some(x => x == null || x === '');
		if (fieldsAreEmpty) {
			alert("Not all the fields were filled, check again");
		}
		else {
			const questionColRef = collection(db, 'questions');
			addDoc(questionColRef, {
				question: question_data.questionText,
				readwrite: question_data.readwriteAns,
				kinetic: question_data.kineticAns,
				audio: question_data.audioAns,
				visual: question_data.visualAns
			})
		}

		setQuestion_data({
			questionText: "",
			readwriteAns: "",
			kineticAns: "",
			audioAns: "",
			visualAns: ""
		})
	}

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/login");
	}, [user, loading]);

	return (
		<div className="edit-questions">
			<button className="bg-red-600 text-white py-0.5 px-2 rounded-md"
							onClick={logout}>Logout</button>
			<div className="text-slate-800 bg-slate-100 text-left md:max-w-xl md:mx-auto rounded-md m-5 py-5 px-5 shadow-md">
				<label className="font-medium">Question: <input type="text" 
													name="questionText" 
													value={question_data.questionText}
													onChange={handleChange}
													className="border-2 border-slate-500 w-full mb-5"  /></label>
				<label className="">Read/Write Answer: <input type="text" 
													name="readwriteAns" 
													value={question_data.readwriteAns}
													onChange={handleChange}
													className="border-2 border-slate-500 w-full"  /></label>
				<label className="">Kinetic Answer: <input type="text" 
													name="kineticAns" 
													value={question_data.kineticAns}
													onChange={handleChange}
													className="border-2 border-slate-500 w-full"  /></label>
				<label className="">Audio Answer: <input type="text" 
													name="audioAns" 
													value={question_data.audioAns}
													onChange={handleChange}
													className="border-2 border-slate-500 w-full"  /></label>
				<label className="">Visual Answer: <input type="text" 
													name="visualAns" 
													value={question_data.visualAns}
													onChange={handleChange}
													className="border-2 border-slate-500 w-full mb-5"  /></label>
								
				<div className=" flex justify-center">
					<button className="bg-red-600 px-2 py-1 text-white rounded-md" onClick={submitQuestion}>Submit Question</button>
				</div>
			</div>
		</div>
	)
}

export default EditQuestions