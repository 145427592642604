import { Outlet } from "react-router-dom";


function GeniusTemplate() {

  return (
    <div className="Questions max-w-2xl justify-center mx-auto">

      <Outlet />
    </div>
  );
}

export default GeniusTemplate;