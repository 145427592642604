import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Questions from "./components/Questions";
import EditQuestions from "./components/EditQuestions";
import Login from "./components/Login";
import YourGenius from "./components/YourGenius";
import GeniusTemplate from "./components/GeniusTemplate";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="header bg-gradient-to-br via-indigo-600 from-purple-600 to-sky-600 text-center text-slate-100 pb-9 min-h-screen">
          <h1 className='font-medium text-2xl py-3 align-middle'><strong className="text-4xl align-middle">🧠</strong>   <span className="align-middle">Which Genius are You?</span></h1>
          <Routes>
            <Route path="/" element={<Questions />} />
            <Route path="/edit-questions" element={<EditQuestions />} />
            <Route path="/login" element={<Login />} />
            <Route path="your-genius" element={<GeniusTemplate />}>
              <Route path=":learningStyle" element={<YourGenius />} />
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
