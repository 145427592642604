import React, { useEffect } from "react";
import {  GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

function Login() {
	const provider = new GoogleAuthProvider();

	const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate();

	const googleSignIn = () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				// ...
			}).catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The AuthCredential type that was used.
				const credential = GoogleAuthProvider.credentialFromError(error);
				// ...
			});
	}

	useEffect(() => {
		if (user) navigate("/edit-questions")	
	}, [user]);

	return (
		<div className="edit-questions">
			<h1 className="text-lg font-medium mb-7"></h1>
			<button className="bg-red-600 text-white text-xl font-medium p-6 rounded-md"
							onClick={function(e) {
								googleSignIn()
							}}
			>Login with Google</button>
		</div>
	)
}

export default Login;