import { useParams } from "react-router-dom";


function YourGenius() {
  var { learningStyle } = useParams();

  const style = {
    kinetic: "Kinetic",
    auditory: "Auditory",
    readwrite: "Reading/Writing",
    visual: "Visual"
  }

  const geniuses = {
    kinetic: [
      "Michael Jordan",
      "Babe Ruth",
      "Charlie Chaplin",
      "Norm Abrams"
    ],
    auditory: [
      "Beethoven",
      "Paul McCartney",
    ],
    readwrite: [
      "Stephen King",
      "Mark Twain",
      "C. S. Lewis",
    ],
    visual: [
      "Albert Einstein",
      "Steven Spielberg",
      "Pablo Picasso",
      "Leonardo da Vinci",
      "Frank Lloyd Wright"
    ]
  }

  const description = {
    kinetic: [
      `Kinesthetic learners learn by touching and doing. They work best in interactive environments where they can involve physical actions in their learning process.`,
    `As a kinesthetic learner you tend to tinker or move around when you are bored, and you struggle to sit still if you are not doing something. You often use hand gestures when you are speaking and often communicate through physical actions or touching. You also find concepts more concrete through practice than when they are presented abstractly.`
    ],
    auditory: [
      `Auditory or aural learners learn best through speech and sounds. They get a lot out of lectures and are good at remembering things that are spoken aloud.`, 
      `As an auditory learner you may not instantly understand graphs and charts and might read slowly. You enjoy music and quickly remember the lyrics to songs that you hear. You work well in groups and might pick up new languages quicker by conversing with others or listening to them speak the language.`
    ],
    readwrite: [
      `Reading and writing learners prefer to take in information through text. They like to write important things down in order to remember them and often refer to available texts for additional information.`,
      `As a reading and writing learner you tend to take lots of notes during class, enjoy making lists, reading definitions and creating presentations. You excel at writing essays and prefer a teacher to use an overhead or give out handouts for the class.`

    ],
    visual: [
      `Visual learners retain information best by seeing pictures, graphs, videos or by reading lists. They tend to capture concepts as pictures in their head for reference or try to visualize in their mind what they are learning.`,
      `As a visual learner you generally like things to be neat and tidy, you might have trouble with spoken directions and can find sounds distracting. Art and visual aesthetics are important to you and if you are bored, you will often find something to watch to fill your time.`
    ]
  }

  const links = {
    kinetic: [
      {
        name: 'vark-learn.com',
        link: 'https://vark-learn.com/strategies/kinesthetic-strategies/'
      },
      {
        name: 'indeed.com/kinesthetic-learning',
        link: 'https://www.indeed.com/career-advice/career-development/kinesthetic-learning-strategies'
      }
    ],
    auditory: [
      {
        name: 'vark-learn.com',
        link: 'https://vark-learn.com/strategies/aural-strategies/'
      },
      {
        name: 'medium.com/@homespunandhandson',
        link: 'https://medium.com/@homespunandhandson/vark-learning-styles-the-auditory-learner-90ce0fd434ed'
      },
      {
        name: 'educationplanner.org',
        link: 'http://www.educationplanner.org/students/self-assessments/learning-styles-styles.shtml#:~:text=If%20you%20are%20a%20visual,see%20what%20you%20are%20learning'
      },
    ],
    readwrite: [
      {
        name: 'vark-learn.com',
        link: 'https://vark-learn.com/strategies/readwrite-strategies/'
      },
      {
        name: 'ace.fsu.edu',
        link: 'https://ace.fsu.edu/sites/g/files/upcbnu296/files/Study_Strategies_by_Learning_Styles.pdf'
      },
    ],
    visual: [
      {
        name: 'educationplanner.org',
        link: 'http://www.educationplanner.org/students/self-assessments/learning-styles-styles.shtml#:~:text=If%20you%20are%20a%20visual,see%20what%20you%20are%20learning.'
      },
      {
        name: 'thoughtco.com',
        link: 'https://www.thoughtco.com/visual-learning-style-3212062'
      },
      {
        name: 'showmeimages.com',
        link: 'https://www.showmeimages.com/news/visual_learner.html'
      }
    ]
  }

  return (
    <>
      <div className="max-w-2xl justify-center mx-auto">
        <div className="text-slate-800 bg-slate-100 rounded-md m-5 py-5 shadow-md">
          <h1 className='font-bold text-2xl'>Your learning style is <span className="text-red-500">{style[learningStyle]}!</span></h1>
          <p className="mx-9 my-4 text-left">There geniuses who learn in the {style[learningStyle]} style. Here's a list of them.</p>
        </div>
      </div>
      <div className="max-w-2xl justify-center mx-auto">
        <div className="text-slate-800 bg-slate-100 rounded-md m-5 py-5 shadow-md">
          <h1 className='font-bold text-2xl'>Who else learns this way?</h1>
          <p className="mx-9 my-4 text-left">There are <strong>geniuses</strong> who learn in the {style[learningStyle]} style. Here's a list of them:</p>
          <ul className="text-left mx-9 font-medium">
            {geniuses[learningStyle].map((person) => <li key={person}>{person}</li>)}
          </ul>
        </div>
      </div>
      <div className="max-w-2xl justify-center mx-auto">
        <div className="text-slate-800 bg-slate-100 rounded-md m-5 py-5 shadow-md">
          <h1 className='font-bold text-2xl'>Tell me more!</h1>
          <div className="mx-9 my-4 text-left">
            {description[learningStyle].map(text => <p key={text} className="mb-3">{text}</p>)}
          </div>
        </div>
      </div>
      <div className="max-w-2xl justify-center mx-auto">
        <div className="text-slate-800 bg-slate-100 rounded-md m-5 py-5 shadow-md">
          <h1 className='font-bold text-2xl'>Where can I learn more?</h1>
          <p className="mx-9 my-4 text-left">Here are some links to learn more about the {style[learningStyle]} learning style:</p>
          <div className="mx-9 my-4 text-center">
            <ul className="">
            {links[learningStyle].map(list_item => <li key={list_item['link']}><a href={list_item['link']} target='_blank' rel="noreferrer" className="text-blue-600 underline">{list_item['name']}</a></li>)}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default YourGenius;