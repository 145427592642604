import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";


function Questions() {
  let navigate = useNavigate();

  const [questions, setQuestions] = useState([]);

  const countChecks = () => {
    const num_checks_rw = document.querySelectorAll('input#readwrite[type=checkbox]:checked').length
    const num_checks_kinetic = document.querySelectorAll('input#kinetic[type=checkbox]:checked').length
    const num_checks_audio = document.querySelectorAll('input#audio[type=checkbox]:checked').length
    const num_checks_visual = document.querySelectorAll('input#visual[type=checkbox]:checked').length

    const count_dict = {
      kinetic: num_checks_kinetic,
      auditory: num_checks_audio,
      readwrite: num_checks_rw,
      visual: num_checks_visual
    }
    
    function getMaxValueKey(obj) {
      return Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);
    }
    
    var max_count = getMaxValueKey(count_dict);

    navigate(`/your-genius/${max_count}`);
    

  }

  useEffect(() => {
    const getQuesitons = async () => {
      const questionsCollectionRef = collection(db, 'questions');
      const question_data = await getDocs(questionsCollectionRef);
      setQuestions(question_data.docs.map((doc) => ({...doc.data(), id:doc.id})));
    };

    getQuesitons();

  }, []);

  return (
    <div className="Questions max-w-2xl justify-center mx-auto">
      <div className="text-slate-800 bg-slate-100 rounded-md m-5 py-5 shadow-md">
        <h1 className='font-bold text-2xl'>Questions</h1>
				<p className="mx-9 my-4 text-left">Choose the answer which best explains your preference and click the box next to it. Please click more than one if a single answer does not match your perception. Leave blank any question that does not apply.</p>
      </div>
      <div className="questions">
        {questions.map((question) => {
          return (
            <div className="text-slate-800 bg-slate-100 text-left rounded-md m-5 py-5 px-5 shadow-md" key={question.id}>
              <div className="bg-slate-200 -mx-5 -mt-5 mb-3 p-5 pb-3 rounded-t-md">
                <h1 className="text-lg font-medium">{question.question}</h1>
              </div>
              
              <label className="cursor-pointer"><input type="checkbox" id="readwrite" className="cursor-pointer" /> {question.readwrite}</label><br></br>
              <label className="cursor-pointer"><input type="checkbox" id="kinetic" className="cursor-pointer" /> {question.kinetic}</label><br></br>
              <label className="cursor-pointer"><input type="checkbox" id="audio" className="cursor-pointer" /> {question.audio}</label><br></br>
              <label className="cursor-pointer"><input type="checkbox" id="visual" className="cursor-pointer" /> {question.visual}</label><br></br>
            </div>
          )
        })}
      </div>
      <div className="submitting">
        <button className="bg-gradient-to-br from-red-600 to-red-700 hover:to-red-600 bg-red-600 pink-600 text-white font-semibold px-4 py-3 
                            rounded-lg shadow-xl"
                onClick={function(e) {
                  countChecks()
                }}>
                              Which Genius are You?
        </button>
      </div>
    </div>
  );
}

export default Questions;